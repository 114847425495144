import { ReactElement } from 'react'
import { Company } from './Company'
import { SegmentDto } from './Segment'

export enum ChartName {
    DailyUsers = 'dailyUsers',
    CumulativeUsers = 'dailyUsersCumulative',
    Categories = 'treeMap',
    Languages = 'language-chart',
    InterestIntensity = 'intensityChart',
    Countries = 'countryChart',
    OS = 'os',
    Browser = 'browserChart',
    Device = 'deviceType',
    Activity = 'peakUserActivity',
    Pageviews = 'pageviewChart',
    Sessions = 'sessionChart',
    RefDomains = 'path-exploration-ref',
    DesDomains = 'path-exploration-des',
    AudienceOverlap = 'audienceOverlap',
    Network = 'networkChart',
    Affinity = 'affinityChart',
    NetworkCustom = 'networkChartCustom'
}

export type Score = {
    adjacent_category: string
    affinity: number
}

export interface GraphData {
    'world-map': Addressability[]
    addressability: Addressability[]
    os: Addressability[]
    browser: Addressability[]
    [ChartName.Device]: Addressability[]
    lang: Addressability[]
    [ChartName.Categories]: TreeMapData[]
    'language-chart': { name: string; value: number }[]
    [ChartName.Countries]: { name: string; value: number }[]
    [ChartName.InterestIntensity]: InterestIntensity[]
    [ChartName.AudienceOverlap]: VennDiagram
    [ChartName.Network]: NetworkChartType
    [ChartName.Affinity]: { [key: string]: Score[] }
    [ChartName.NetworkCustom]: { [key: string]: NetworkChartType }
    [ChartName.DailyUsers]: DailyUsers
    [ChartName.CumulativeUsers]: DailyUsers
    [ChartName.Sessions]: ReturningUsers
    [ChartName.Pageviews]: ReturningUsers
    [ChartName.Activity]: Array<number[]>
    [ChartName.Browser]: { name: string; value: number }[]
    totalAudienceSize: number
    tag_on?: boolean
    'path-exploration-ref': { links: { source: string; value: number }[] }
    'path-exploration-des': { links: { source: string; value: number }[] }
}

export interface CurrentFilters {
    [FilterType.Categories]: { name: string; value: string[] }
    [FilterType.InterestIntensity]: { name: string; value: string[] }
    [FilterType.Countries]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Browser]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Device]: { name: string; value: { label: string; value: string }[] }
    [FilterType.OS]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Domains]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Pageviews]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Sessions]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Languages]: { name: string; value: { label: string; value: string }[] }
    [FilterType.RefDomains]: { name: string; value: { label: string; value: string }[] }
    [FilterType.DesDomains]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Activity]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Keywords]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Topics]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Cities]: { name: string; value: { label: string; value: string }[] }
    [FilterType.Lookalike]: { name: string; value: string[] }
}

export interface CompanyInsights {
    [key: string]: GraphData
}

export interface Addressability {
    name: string
    value: number
}

export interface InterestIntensity {
    category: string
    count: number
    intensity: string
}

export interface DailyUsers {
    cookie_values: number[]
    cookieless_values: number[]
    single_session_values: number[]
    dates: string[]
}

export interface NetworkChartType {
    domain: string
    nodes: NetworkChartNode[]
    links: Link[]
    categories: Category[]
}

export interface NetworkChartNode {
    id: string
    name?: string
    symbolSize: number
    value: number
    category: number
    x: number
    y: number
}

export interface Category {
    name: string
}

export interface Link {
    source: string
    target: string
    source_label: string
    target_label: string
    weight: number
    lineStyle: LineStyle
}

export interface LineStyle {
    width: number
}

export interface Node {
    itemStyle: any | { borderWidth: number; borderColor: string }
    id: string
    name?: string
    symbolSize: number
    value: number
    category?: number
    x: number
    y: number
    label?: string
}

export interface ReturningUsers {
    values: number[]
    groups: string[]
}

export interface TreeMapData {
    taxonomy: TreeMapTaxonomy
    value: number
    name: string
    path: string
    children?: TreeMapData[]
}

export interface TreeMapDataCustom {
    [key: string]: TreeMapData
}

export enum Network {
    Entire = 'Your entire network'
}

export enum TreeMapTaxonomy {
    IAB = 'IAB',
    Custom = 'custom-tax'
}

export enum InsightsPage {
    Snapshot = 'Audience Snapshot',
    Exploration = 'Audience Exploration'
}

export interface InsightsFilters {
    client_id: string
    is_adv: boolean
    domains: string[]
    network_domains: string[]
    entire_network: boolean
    filters: InsightsFiltersData[] | any
}

export interface InsightsFiltersData {
    [key: string]: string[]
}

export type FilteredOther = {
    [key in FilterType]: { name: string; value: number }[]
}

export interface InsisghtsTabFiltersData {
    field: FilterType
    value: string[]
}

export interface InsightsDescription {
    title: string
    component: ReactElement
    value: {
        interpretation: () => JSX.Element
        conclusions: () => JSX.Element
        howToUse?: () => JSX.Element
        suggestions?: () => JSX.Element
    }
}

export interface InsightsNavbarProps {
    domainData: { label: string; value: GraphData | any }
    domainOptions: { label: string; value: GraphData }[]
    reportId: number
    company: Company
    date: number
    setDate: (value: any) => void
    handleGetInsights: (id: number, isAdvertiser: boolean, date: number, domain: string, forceExecute?: boolean) => void
    setDomainData: (value: any) => void
    selectedSegment: SegmentDto
    setSelectedSegment: (value: SegmentDto) => void
    showLookerReport: boolean
    setShowLookerReport: (value: boolean) => void
    isDifferentCompany: boolean
}

export enum FilterType {
    Categories = 'category_name',
    Languages = 'uag_lang',
    Countries = 'country',
    Keywords = 'keyword',
    Topics = 'topicIds',
    Cities = 'city',
    InterestIntensity = 'category_score',
    OS = 'device_os',
    Browser = 'device_browser',
    Device = 'device_type',
    Activity = 'day_of_week_hour',
    Pageviews = 'pageview_id',
    Sessions = 'session_id',
    Domains = 'domains',
    RefDomains = 'urlref_domain',
    DesDomains = 'urldes_domain',
    Lookalike = 'adjacent_category_name'
}

export interface VennDiagram {
    nodes: { id: string; name: string; symbolSize: number; value: number }[]
    links: { source: string; target: string; weight: number; lineStyle: { width: number } }[]
}

export interface MaintenanceMode {
    is_under_maintenance: boolean
}
