import { ApiService } from './apiService'
import { baseApiUrl } from '../config'
import { User } from '../types/User'
import { UserInviteDto } from '../types/UserInviteDto'
import { UserResetDto } from '../types/UserResetDto'
import { LocalStorageKeys } from '../types/LocalStorage'

export class AuthService extends ApiService {
    async invite(model: UserInviteDto): Promise<User> {
        return this.post(`/api/v2/company/${model.companyId}/account`, model)
    }

    async delete(companyId: number, accountId: number): Promise<void> {
        return this.del(`/api/admin/v2/company/${companyId}/account/${accountId}`)
    }

    async getVerification(email: string) {
        return this.post(`/api/public/v2/verification`, { email: email })
    }

    async getVerificationAdmin(userId: number) {
        return this.post(`/api/admin/v2/verification`, { accountId: userId })
    }

    async resetPassword(model: UserResetDto): Promise<void> {
        return this.post('/api/public/v2/reset', model, false, true)
    }

    async reset(email: string, password: string): Promise<void> {
        return this.post('/api/public/v2/reset', { email: email, password: password })
    }

    async getUsers(companyId: number): Promise<User[]> {
        return this.get(`/api/v2/company/${companyId}/account`)
    }

    async changeRole(user: User) {
        return this.patch(`/api/admin/v2/account/${user.id}/role?role=${user.role}`)
    }

    async updateName(user: User) {
        return this.patch(`/api/v3/account`, user)
    }

    handleLocaleStorage = (auth: { token: string }) => {
        localStorage.setItem(LocalStorageKeys.AuthenticationCode, JSON.stringify(auth))

        const exp = JSON.parse(atob(auth.token.split('.')[1])).exp
        localStorage.setItem(LocalStorageKeys.ExpirationTime, JSON.stringify(exp))
    }

    async login(email: string, password: string): Promise<string> {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password })
        }

        const response = await fetch(`${this.baseUrl}/api/public/v2/login`, requestOptions)
        const auth = await this.handleResponse(response)

        if (auth && auth.token) {
            this.handleLocaleStorage(auth)
        }

        return auth ? auth.token : null
    }

    async login2F(code: string, email: string): Promise<string> {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: code, email: email })
        }

        const response = await fetch(`${this.baseUrl}/api/public/v2/login/otp`, requestOptions)
        const auth = await this.handleResponse(response)

        if (auth.token) {
            this.handleLocaleStorage(auth)
        }

        return auth.token
    }

    async forgetPassword(email: string): Promise<string> {
        return this.post(`/api/public/v2/verification`, { email }, false, true)
    }

    async getMe(): Promise<User> {
        const header = this.authHeader()

        const requestOptions = {
            method: 'GET',
            headers: header
        }
        const response = await fetch(`${this.baseUrl}/api/v2/me`, requestOptions)

        try {
            return await this.handleResponse(response)
        } catch (error) {
            this.logout()
            window.location.replace('/login')
            throw error
        }
    }

    logout() {
        localStorage.removeItem(LocalStorageKeys.AuthenticationCode)
    }
}

export const authService = new AuthService(baseApiUrl)
