import { ApiService } from './apiService'
import { audienceServiceUrl, baseApiUrl } from '../config'
import { CompanyInsights, InsightsFilters, MaintenanceMode } from '../types/GraphData'

export class InsightsService extends ApiService {
    async filterInsights(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post(`${audienceServiceUrl}/estimate`, filters, true)
    }

    async getSnapshot(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post(`${audienceServiceUrl}/static_data`, filters, true)
    }

    async checkMaintenanceMode(): Promise<MaintenanceMode> {
        return this.get(`${audienceServiceUrl}/maintenance`, null, true)
    }

    async getDirectLookalikeCategories(category: string[], companyId?: number): Promise<Record<string, number>> {
        return this.post(`${audienceServiceUrl}/get_lookalike_cats`, { category, companyId }, true)
    }
}

export const insightsService = new InsightsService(baseApiUrl)
