import { ApiService } from './apiService'
import { baseApiUrl } from '../config'
import { CompanyInsights, GraphData, InsightsFilters, MaintenanceMode } from '../types/GraphData'
import { SegmentDto } from '../types'

const audienceServiceUrl = '/api/v3/estimation'

export class InsightsService extends ApiService {
    async filterInsights(filters: InsightsFilters): Promise<GraphData | null> {
        return this.post(`${audienceServiceUrl}/estimate`, filters)
    }

    async getSnapshot(filters: InsightsFilters): Promise<CompanyInsights | null> {
        return this.post(`${audienceServiceUrl}/static_data`, filters)
    }

    async checkMaintenanceMode(): Promise<MaintenanceMode> {
        return this.get(`${audienceServiceUrl}/maintenance`, null)
    }

    async getDirectLookalikeCategories(categoryIds: number[], companyId?: number): Promise<Record<string, number>> {
        return this.post(`${audienceServiceUrl}/get_lookalike_cats`, { category: categoryIds, companyId })
    }

    async getLookalikeTopics(topicIds: number[], companyId?: number): Promise<Record<number, number>> {
        return this.post(`${audienceServiceUrl}/get_adjacent_topics`, { topics: topicIds, companyId })
    }

    async estimateClickHouse(segment: SegmentDto, isAdv: boolean): Promise<{ size: number; singleSite: number }> {
        return this.post(`${audienceServiceUrl}/audience_builder_estimation`, { ...segment, isAdv: isAdv })
    }
}

export const insightsService = new InsightsService(baseApiUrl)
