import { TreeMapData, TreeMapTaxonomy } from '../types/GraphData'

export const flattenNodesArray = (arr: TreeMapData[] | null) => {
    const namesArray: { name: string; value: number; id: number; taxonomy: TreeMapTaxonomy }[] = []

    const flatten = (node: TreeMapData[]) => {
        node?.forEach((item) => {
            namesArray.push({ name: item.name, value: item.value, id: item.id, taxonomy: item.taxonomy })

            if (item.children?.length) {
                flatten(item.children)
            }
        })
    }

    flatten(arr)

    return namesArray
}
