import { makeAutoObservable } from 'mobx'
import { ToastData } from '../types/ToastData'
import { v4 as uuidv4 } from 'uuid'
import { SortOrder } from '../types/SortOrder'
import { ReactElement } from 'react'
import { LocalStorageKeys } from '../types/LocalStorage'

type ConfirmationModal = {
    title: string
    body: ReactElement
    action: () => Promise<void>
    question?: string
    isDisabled?: boolean
    hideAction?: boolean
    formId?: string
    shouldNotCloseAfterAction?: boolean
}

export class AppStore {
    isSidebarClosed = localStorage[LocalStorageKeys.isSidebarClosed] === 'true' || localStorage[LocalStorageKeys.isSidebarClosed] === undefined
    isOpenRightSidebar = false
    isOpenCreateSegmentModal = false
    isOpenCreateCampaignModal = false
    isCreateRetargetingSegment = false
    isCurrentlyEstimated = false
    isTourOpen = false
    toastNotifications: ToastData[] = []
    confirmationModal: ConfirmationModal | null = null
    sidebarData: any = null
    sortBy: { value: string; order: SortOrder.ASC | SortOrder.DESC; customOrder?: any[]; isSameOrder?: boolean; oldOrder?: any[] } = null
    isMessageUpdated: boolean = false
    pendingRequests = 0

    constructor() {
        makeAutoObservable(this)
    }

    setIsCurrentlyEstimated(isEstimated: boolean) {
        this.isCurrentlyEstimated = isEstimated
    }

    setIsTourOpen(isOpen: boolean) {
        this.isTourOpen = isOpen
    }

    checkMessageUpdated(isUpdated: boolean) {
        this.isMessageUpdated = isUpdated
    }

    setConfirmationModalDisabled(isDisabled: boolean) {
        this.confirmationModal.isDisabled = isDisabled
    }

    setConfirmationModal(confirmationModal: ConfirmationModal | null) {
        this.confirmationModal = confirmationModal
    }

    setToastNotification(toastNotification: ToastData) {
        toastNotification.id = uuidv4()
        toastNotification.body = toastNotification.body ?? 'Something went wrong'
        toastNotification.isHidden = toastNotification.isHidden ?? false
        this.toastNotifications.push(toastNotification)
    }

    deleteToast(id: string) {
        const index = this.toastNotifications.findIndex((x) => x.id === id)
        if (index >= 0) {
            this.toastNotifications.splice(index, 1)
        }
    }

    setCreateSegmentModal(isOpen: boolean) {
        this.isOpenCreateSegmentModal = isOpen
    }

    setCreateCampaignModal(isOpen: boolean) {
        this.isOpenCreateCampaignModal = isOpen
    }

    setCreateRetargetingSegment(isOpen: boolean) {
        this.isCreateRetargetingSegment = isOpen
    }

    setSidebarData(data: { title: string; value: any; component: ReactElement }) {
        this.sidebarData = data
    }

    setSortBy(sortBy: { value: string; order: SortOrder.ASC | SortOrder.DESC; customOrder?: any[]; isSameOrder?: boolean; oldOrder?: any[] }) {
        this.sortBy = sortBy
    }

    toggleSidebar() {
        this.isSidebarClosed = !this.isSidebarClosed
        localStorage[LocalStorageKeys.isSidebarClosed] = this.isSidebarClosed ? 'true' : 'false'
    }

    toggleRightSidebar() {
        this.isOpenRightSidebar = !this.isOpenRightSidebar
    }

    incrementRequests() {
        this.pendingRequests++
    }
    decrementRequests() {
        this.pendingRequests--
    }
}
