export enum InterestScore {
    Moderate = 'Moderate',
    Good = 'Good',
    High = 'High',
    VeryHigh = 'Very High',
    Highest = 'Highest'
}

export const handleSliderValue = (value: InterestScore) => {
    switch (value) {
        case InterestScore.Moderate:
            return 0
        case InterestScore.Good:
            return 0.2
        case InterestScore.High:
            return 0.4
        case InterestScore.VeryHigh:
            return 0.6
        case InterestScore.Highest:
            return 0.8
        default:
            return 0
    }
}

export const handleInterestValue = (value: number) => {
    switch (value) {
        case 0:
            return InterestScore.Moderate
        case 0.2:
            return InterestScore.Good
        case 0.4:
            return InterestScore.High
        case 0.6:
            return InterestScore.VeryHigh
        case 0.8:
            return InterestScore.Highest
        default:
            return InterestScore.Moderate
    }
}
