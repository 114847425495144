import { ToastType } from '../types/ToastData'
import { makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from './rootStore'
import {
    ChartName,
    CompanyInsights,
    CurrentFilters,
    FilterType,
    GraphData,
    InsightsFilters,
    InsightsFiltersData,
    Network,
    TreeMapTaxonomy
} from '../types/GraphData'
import { InsightsService } from '../services/insightsService'
import { segmentService } from '../services/segmentService'
import { SessionStorageKeys } from '../types/SessionStorage'
import { Company } from '../types/Company'
import { CategoryItem, SegmentDto, TargetingDto } from '../types/Segment'
import { LocalStorageKeys } from '../types/LocalStorage'
import { MAX_FILTERS_NUMBER } from '../pages/Insights/filtering/constants'
import { flattenNodesArray } from '../utils/flattenNodesArray'
import { DeviceOsOptions } from '../types/AudienceBuilder'
import { genKey } from '../utils/genKey'

export type InsightsFiltersTab = { field: FilterType; value: FilterValue; _id: string }

//TODO: get rid of any
type FilterValue = string[] | number[] | string | number | any

export class InsightsStore {
    private insightsService: InsightsService
    private rootStore: RootStore

    companyInsights: GraphData = null
    insightsFilters = InsightsStore.getEmptyFiltersObject()
    insightsNetwork: string[]
    insightsFiltersTab: InsightsFiltersTab[] = []
    insightsCurrentData: CurrentFilters = null
    insightsFilteredData: InsightsFilters = null
    filteredOther: InsightsFiltersData = {}
    categories: CategoryItem[] = []
    selectedNode: string = null
    selectedNodeLabel: string = null
    isMaintenanceMode: boolean
    error: string = null

    constructor(rootStore: RootStore, insightsService: InsightsService) {
        this.insightsService = insightsService
        this.rootStore = rootStore
        this.companyInsights = null
        this.insightsCurrentData = null
        this.insightsFilteredData = null
        this.insightsFiltersTab = [{ field: null, value: [], _id: genKey() }]
        this.filteredOther = {
            country: [],
            uag_lang: []
        }
        this.categories = []
        this.isMaintenanceMode = false
        makeAutoObservable(this)
    }

    async getCategories() {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const categories = await segmentService.getCategories()

            runInAction(() => {
                this.categories = categories
            })
        } catch (error) {
            runInAction(() => {
                this.error = this.handleError(error)
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getLookalikeCategories(categories: number[], companyId?: number) {
        let lookalikeData: string[] = []

        try {
            const lookalikeCategories = await this.insightsService.getDirectLookalikeCategories(categories, companyId)

            runInAction(() => {
                const index = this.insightsFiltersTab.findIndex((x) => x.field === FilterType.Lookalike)
                lookalikeData = Object.keys(lookalikeCategories || {})

                if (index !== -1) {
                    this.insightsFiltersTab[index].value = lookalikeData
                } else {
                    this.insightsFiltersTab.push({
                        field: FilterType.Lookalike,
                        value: lookalikeData,
                        _id: genKey()
                    })
                }
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get adjacent audiences',
                body: this.handleError(error)
            })
        } finally {
            if (!lookalikeData.length) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Warning,
                    title: 'No adjacent audiences found',
                    body: 'There is no category meeting affinity criteria'
                })
            }
        }
    }

    handleEmptyCategoriesMessage = () => {
        this.rootStore.appStore.setToastNotification({
            title: 'No categories selected',
            body: 'Please select at least one category from the interests section to change engagement level',
            type: ToastType.Warning
        })
    }

    mapCurrentDataOptions = (data: GraphData, categories: string[] | CategoryItem[], domains: string[]) => {
        const generateActivity = (): [number, number][] => {
            const combinations: [number, number][] = []

            for (let i = 0; i < 7 * 24; i++) {
                const dayOfWeek = Math.floor(i / 24)
                const hour = i % 24
                combinations.push([dayOfWeek, hour])
            }

            return combinations
        }

        const devices = ['Desktop', 'Mobile', 'Tablet', 'Others']

        const os = [
            DeviceOsOptions.Android,
            DeviceOsOptions.Ios,
            DeviceOsOptions.MacOs,
            DeviceOsOptions.Windows,
            DeviceOsOptions.Linux,
            DeviceOsOptions.Chrome
        ]

        const browsers = ['Chrome', 'Firefox', 'Safari', 'Opera', 'Edge', 'Samsung', 'Other']

        const hours = [
            '00:00',
            '01:00',
            '02:00',
            '03:00',
            '04:00',
            '05:00',
            '06:00',
            '07:00',
            '08:00',
            '09:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00'
        ]

        const days = ['Saturday', 'Friday', 'Thursday', 'Wednesday', 'Tuesday', 'Monday', 'Sunday']

        // const intensity = ['Moderate', 'Good', 'High', 'Very High', 'Highest']

        const handleActivity = (date: string) => {
            const dayOfWeekString = date.split('-')[0].trim()
            const timeOfDayString = date.split('-')[1].split(':')[0].trim()

            switch (dayOfWeekString) {
                case 'Saturday':
                    return `6_${timeOfDayString}`
                case 'Friday':
                    return `5_${timeOfDayString}`
                case 'Thursday':
                    return `4_${timeOfDayString}`
                case 'Wednesday':
                    return `3_${timeOfDayString}`
                case 'Tuesday':
                    return `2_${timeOfDayString}`
                case 'Monday':
                    return `1_${timeOfDayString}`
                case 'Sunday':
                    return `0_${timeOfDayString}`
                default:
                    return null
            }
        }

        return Object.entries(data).reduce((result: any, [key]) => {
            return {
                ...result,
                [FilterType.Countries]: {
                    name: key,
                    value: []
                },
                [FilterType.Activity]: {
                    name: key,
                    value: generateActivity().map((x: number[]) => {
                        const date = `${days[x[0]]} - ${hours[x[1]]}`
                        return { label: date, value: handleActivity(date) }
                    })
                },
                // [FilterType.InterestIntensity]: {
                //     name: key,
                //     value: intensity.map((x: string) => {
                //         return { label: x, value: x }
                //     })
                // },
                [FilterType.Pageviews]: {
                    name: key,
                    value: [
                        { label: '1', value: 1 },
                        { label: '2-3', value: [2, 3] },
                        { label: '4-5', value: [4, 5] },
                        { label: '6-9', value: [6, 7, 8, 9] },
                        {
                            label: '10+',
                            value: Array.from({ length: 31 }, (_, index) => index + 10)
                        }
                    ]
                },
                [FilterType.Sessions]: {
                    name: key,
                    value: [
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 3 },
                        { label: '4', value: 4 },
                        {
                            label: '5+',
                            value: Array.from({ length: 36 }, (_, index) => index + 5)
                        }
                    ]
                },
                [FilterType.Device]: {
                    name: key,
                    value: devices.map((x: string) => {
                        return { label: x, value: x }
                    })
                },
                [FilterType.Browser]: {
                    name: key,
                    value: browsers.map((x: string) => {
                        return { label: x, value: x }
                    })
                },
                [FilterType.OS]: {
                    name: key,
                    value: os.map((x: string) => {
                        return { label: x, value: x }
                    })
                },
                [FilterType.Categories]: {
                    name: key,
                    value: categories
                },
                [FilterType.Keywords]: {
                    name: key,
                    value: []
                },
                [FilterType.Topics]: {
                    name: key,
                    value: []
                },
                [FilterType.Cities]: {
                    name: key,
                    value: []
                },
                domains: {
                    name: 'domains',
                    value: domains
                        .map((x) => {
                            return { label: x, value: x }
                        })
                        .slice(1)
                }
            }
        }, {})
    }

    setInsightsCurrentData = (data: { label: string; value: GraphData }, categories?: string[], domains?: string[]) => {
        if (data && data.value) {
            const res = categories
                ? this.mapCurrentDataOptions(data.value, categories, domains)
                : this.mapCurrentDataOptions(data.value, this.categories, Object.values(this.insightsNetwork))
            this.insightsCurrentData = res
        }
    }

    setSegmentFilters = (data: SegmentDto) => {
        this.insightsFiltersTab = []

        const mapKeys = (key: string) => {
            switch (key) {
                case 'categoryIds':
                    return FilterType.Categories
                case 'keyword':
                    return FilterType.Keywords
                case 'country':
                    return FilterType.Countries
                case 'deviceType':
                    return FilterType.Device
                case 'deviceBrowser':
                    return FilterType.Browser
                case 'deviceOs':
                    return FilterType.OS
                case 'topicIds':
                    return FilterType.Topics
                case 'city':
                    return FilterType.Cities
                case 'lookalikeCats':
                    return FilterType.Lookalike
                default:
                    return null
            }
        }

        if (data?.targeting) {
            Object.keys(data.targeting).map((key: keyof TargetingDto) => {
                const mappedKey = mapKeys(key)

                if (mappedKey && data.targeting[key]?.length > 0) {
                    this.insightsFiltersTab.push({ field: mappedKey, value: data.targeting[key as keyof TargetingDto], _id: genKey() })
                }
            })

            this.insightsFiltersTab.push({ field: FilterType.InterestIntensity, value: [data.targeting.engagement], _id: genKey() })
        }
    }

    handleSelectFilters = (value: string | number, name: FilterType) => {
        this.insightsFilters[name].includes(value)
            ? (this.insightsFilters[name] = this.insightsFilters[name].filter((x: string | number) => x !== value))
            : (this.insightsFilters[name] = [...this.insightsFilters[name], value])
    }

    handleSelectFiltersTab = (filter: InsightsFiltersTab, i: number) => {
        if (this.insightsFiltersTab[i]) {
            this.insightsFiltersTab[i] = filter
        } else {
            this.insightsFiltersTab.push({ field: filter.field, value: [], _id: filter._id })
        }
    }

    removeFilters = (name: FilterType) => {
        this.insightsFilters[name] = []
    }

    removeFiltersTab = (field: string) => {
        this.insightsFiltersTab = this.insightsFiltersTab.filter((x) => x.field !== field)
    }

    removeFiltersTabAll = () => {
        this.insightsFiltersTab = [{ field: null, value: [], _id: genKey() }]
    }

    mapFiltersToOther = (arr: string[], type: FilterType) => {
        if (Array.isArray(this.insightsFilters[type])) {
            this.insightsFilters[type] = [
                ...this.insightsFilters[type].filter((element) => !arr.includes(element as string)),
                'Other'
            ] as string[]
        }
    }

    setOther = (arr: { name: string; value: number }[], type: FilterType) => {
        this.filteredOther[type] = arr.map((element: { name: string; value: number }) => element.name)
    }

    filterOther = (type: FilterType) => {
        if (!Array.isArray(this.insightsFilters[type])) return []

        return this.insightsFilters[type].some((x) => x === 'Other')
            ? [...this.insightsFilters[type].filter((element: string) => element !== 'Other'), ...this.filteredOther[type]]
            : [...this.insightsFilters[type]]
    }

    setDefaultExploration = () => {
        this.insightsFiltersTab = [{ field: null, value: [], _id: genKey() }]
        this.companyInsights = sessionStorage.getItem(SessionStorageKeys.ExplorationDefault)
            ? JSON.parse(sessionStorage.getItem(SessionStorageKeys.ExplorationDefault))
            : null
    }

    setFilteredData = (
        domainData: { label: string; value: GraphData },
        domainOptions: { label: string; value: GraphData }[],
        companyId: number,
        date: number,
        company: Company
    ) => {
        const newFilters = { ...this.insightsFilters }

        const convertToFilter = (str: string) => {
            const parts = str.split(' - ')

            const day = parts[1]
            const time = parts[0]

            return `${day}_${time}`
        }

        const handleViews = (views: string[]) => {
            const getNumbers = (start: number, end: number) => {
                const numbers = []
                for (let i = start; i <= end; i++) {
                    numbers.push(i.toString())
                }
                return numbers
            }

            return views
                .map((x: string) => {
                    if (x.length > 0 && x.includes('+')) {
                        return getNumbers(+x.split('+')[0], 40)
                    }
                    if (x.length > 0 && x.includes('-')) {
                        return getNumbers(+x.split('-')[0], +x[2])
                    }
                    if (x.length > 0) {
                        return x[0]
                    } else {
                        return x
                    }
                })
                .flat()
        }

        const handleActivity = (date: string) => {
            const dayOfWeekString = date.split('_')[0]

            const timeOfDayString =
                +date.split('_')[1].split(':')[0] <= 10 ? date.split('_')[1].split(':')[0] : `${date.split('_')[1].split(':')[0]}`

            switch (dayOfWeekString) {
                case 'Sat':
                    return `6_${timeOfDayString}`
                case 'Fri':
                    return `5_${timeOfDayString}`
                case 'Thu':
                    return `4_${timeOfDayString}`
                case 'Wed':
                    return `3_${timeOfDayString}`
                case 'Tue':
                    return `2_${timeOfDayString}`
                case 'Mon':
                    return `1_${timeOfDayString}`
                case 'Sun':
                    return `0_${timeOfDayString}`
                default:
                    return null
            }
        }

        if (newFilters.urlref_domain.length > 0 || newFilters.urldes_domain.length > 0) {
            newFilters.urlref_domain = newFilters.urlref_domain.map((x: string) => x.split('>')[0])
            newFilters.urldes_domain = newFilters.urldes_domain.map((x: string) => x.split('>')[1])
        }

        if (newFilters.day_of_week_hour.length > 0) {
            newFilters.day_of_week_hour = newFilters.day_of_week_hour.map((x: string) => convertToFilter(x))
        }

        newFilters.uag_lang = this.filterOther(FilterType.Languages)
        newFilters.country = this.filterOther(FilterType.Countries)

        const copyIfArrayNotEmpty = (obj: InsightsFiltersData) => {
            if (Object.keys(obj).length > 0) {
                const newObj: InsightsFiltersData = {}

                for (const key in obj) {
                    if (key && Array.isArray(obj[key]) && obj[key].length > 0 && key !== FilterType.Domains) {
                        newObj[key] = obj[key]
                    }
                    if (key && Array.isArray(obj[key]) && obj[key].length > 0 && key === FilterType.Activity) {
                        newObj[key] = obj[key].map((x: string) => handleActivity(x))
                    }
                    if (key && Array.isArray(obj[key]) && obj[key].length > 0 && (key === FilterType.Sessions || key === FilterType.Pageviews)) {
                        newObj[key] = handleViews(obj[key])
                    }
                }

                return newObj
            }
        }

        const handleDomains = () => {
            if (newFilters.domains.length === 0) {
                return domainData?.label === 'Your entire network'
                    ? domainOptions.filter((x: { label: string }) => x.label !== 'Your entire network').map((x: { label: string }) => x.label)
                    : [domainData?.label]
            } else {
                return newFilters.domains
            }
        }

        const handleNetwork = () => {
            return domainOptions.filter((x: { label: string }) => x.label !== 'Your entire network').map((x: { label: string }) => x.label)
        }

        const handleInsightsFiltersTab = (arr: { field: FilterType; value: string[] }[]) => {
            return arr
                .filter((x) => {
                    return x.field !== 'domains' && x.field
                })
                .reduce((acc: { [key: string]: string[] }, obj) => {
                    obj.field === FilterType.Pageviews || obj.field === FilterType.Sessions
                        ? (acc[obj.field] = obj.value.flat())
                        : (acc[obj.field] = obj.value)
                    return acc
                }, {})
        }

        /* eslint-disable no-prototype-builtins */
        const combineObjects = (obj1: { [key: string]: string[] }, obj2: { [key: string]: string[] }) => {
            const result = { ...obj1 }

            for (const key in obj2) {
                if (obj2.hasOwnProperty(key)) {
                    if (result.hasOwnProperty(key)) {
                        result[key] = [...result[key], ...obj2[key]]
                    } else {
                        result[key] = obj2[key]
                    }
                }
            }

            return result
        }
        /* eslint-disable no-prototype-builtins */

        const filters = {
            company_id: companyId,
            is_adv: company.isAdvertiser,
            domains: handleDomains(),
            network_domains: handleNetwork(),
            entire_network: domainData ? domainData.label === Network.Entire : true,
            filters: combineObjects(handleInsightsFiltersTab(this.insightsFiltersTab), copyIfArrayNotEmpty(newFilters)),
            span: date
        }

        this.insightsFilteredData = filters
    }

    mergeObjects = (obj1: any, obj2: any) => {
        /* eslint-disable no-prototype-builtins */
        const mergedObj = { ...obj1 }

        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                mergedObj[key] = obj2[key]
            }
        }

        return mergedObj
    }

    hasFilters = () => {
        return (
            this.insightsFiltersTab.some((x) => x.field !== null) ||
            Object.values(this.insightsFilters).some((val) => Array.isArray(val) && val.length > 0)
        )
    }
    /* eslint-enable no-prototype-builtins */

    getData = async (
        filters: InsightsFilters,
        serviceCall: (filters: InsightsFilters) => Promise<GraphData | CompanyInsights>,
        isSnapshot: boolean,
        forceExecute: boolean
    ) => {
        const areSame = sessionStorage.getItem(SessionStorageKeys.InsightsFilters) === JSON.stringify(filters)
        const isDataPresent = isSnapshot
            ? sessionStorage.getItem(SessionStorageKeys.SnapshotDefault)
            : sessionStorage.getItem(SessionStorageKeys.ExplorationDefault)

        if (!areSame || !isDataPresent || forceExecute) {
            const res = await serviceCall(filters)

            if (Object.keys(filters.filters).length === 0) {
                isSnapshot
                    ? sessionStorage.setItem(SessionStorageKeys.SnapshotDefault, JSON.stringify(res))
                    : sessionStorage.setItem(SessionStorageKeys.ExplorationDefault, JSON.stringify(res))

                sessionStorage.setItem(SessionStorageKeys.InsightsFilters, JSON.stringify(filters))
            }
            return res
        }
        if (areSame) {
            const res = isSnapshot
                ? JSON.parse(sessionStorage.getItem(SessionStorageKeys.SnapshotDefault))
                : JSON.parse(sessionStorage.getItem(SessionStorageKeys.ExplorationDefault))
            sessionStorage.setItem(SessionStorageKeys.InsightsFilters, JSON.stringify(filters))
            return res
        }
    }

    setNode = (node: string, label: string) => {
        this.selectedNode = node
        this.selectedNodeLabel = label
    }

    async getMaintenanceMode() {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.insightsService.checkMaintenanceMode()

            runInAction(() => {
                this.isMaintenanceMode = localStorage.getItem(LocalStorageKeys.maintenanceMode) ? false : res.is_under_maintenance
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to check maintenance',
                body: this.handleError(error)
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    isRuckClient = (companyId: number) => {
        return companyId === 77
    }

    handleError = (e: any) => {
        if (e?.code === 500) {
            return `We're experiencing server issues at the moment. Please try again in a few seconds`
        }
        if (e?.message) {
            return e.message
        } else {
            return 'Something went wrong'
        }
    }

    async getCompanyInsights(companyId: number, isAdvertiser: boolean, date: number, forceExecute: boolean = false) {
        try {
            this.rootStore.appStore.incrementRequests()

            !this.categories && (await this.getCategories())

            const filters = {
                company_id: companyId,
                is_adv: isAdvertiser,
                domains: this.isRuckClient(companyId) ? ['ruck.co.uk'] : ([] as string[]),
                network_domains: [] as string[],
                entire_network: !this.isRuckClient(companyId),
                filters: {},
                span: date
            }

            const serviceCall = async (filters: InsightsFilters) => {
                return this.insightsService.filterInsights(filters)
            }

            const res = await this.getData(filters, serviceCall, false, forceExecute)

            runInAction(() => {
                this.companyInsights = res
                this.insightsNetwork = Object.keys(res)
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get company insights',
                body: this.handleError(error)
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getCompanySnapshot(companyId: number, isAdvertiser: boolean, date: number, domain: string, forceExecute: boolean = false) {
        try {
            this.rootStore.appStore.incrementRequests()

            const isEntireNetwork = domain === 'Your entire network'

            const filters = {
                company_id: companyId,
                is_adv: isAdvertiser,
                domains: this.isRuckClient(companyId) ? ['ruck.co.uk'] : isEntireNetwork ? [] : ([domain] as string[]),
                network_domains: [] as string[],
                entire_network: this.isRuckClient(companyId) ? !this.isRuckClient(companyId) : isEntireNetwork,
                filters: {},
                span: date
            }

            const serviceCall = async (filters: InsightsFilters) => {
                return this.insightsService.getSnapshot(filters)
            }

            const res = await this.getData(filters, serviceCall, true, forceExecute)

            runInAction(() => {
                this.companyInsights = res
                this.insightsNetwork = Object.keys(res)
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get company insights',
                body: this.handleError(error)
            })
        } finally {
            runInAction(() => {
                this.insightsFilters = InsightsStore.getEmptyFiltersObject()
                this.insightsFiltersTab = [{ field: null, value: [], _id: genKey() }]
            })
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getCompanyExploration(companyId: number, isAdvertiser: boolean, date: number, domain: string) {
        const filtersNumber = Object.keys(this.insightsFilteredData.filters || {})?.length

        if (filtersNumber > MAX_FILTERS_NUMBER) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Warning,
                title: 'Failed to filter insights',
                body: `Max number of filters is ${MAX_FILTERS_NUMBER}. You are trying to apply ${filtersNumber} filters`
            })

            return
        }

        try {
            this.rootStore.appStore.incrementRequests()

            const isEntireNetwork = domain === Network.Entire

            const filters = {
                company_id: companyId,
                is_adv: isAdvertiser,
                domains: this.isRuckClient(companyId) ? ['ruck.co.uk'] : isEntireNetwork ? [] : ([domain] as string[]),
                network_domains: [] as string[],
                entire_network: this.isRuckClient(companyId) ? this.isRuckClient(companyId) : isEntireNetwork,
                filters: this.insightsFilteredData.filters,
                span: date
            }

            const res = await this.insightsService.filterInsights(filters)

            runInAction(() => {
                this.companyInsights = res
                this.insightsNetwork = Object.keys(res)
                this.insightsFiltersTab = Object.entries(filters.filters).map((x: any) => ({ field: x[0], value: x[1], _id: genKey() }))
                this.insightsFilters = InsightsStore.getEmptyFiltersObject()
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get company insights',
                body: this.handleError(error)
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async filterInsights(filters: InsightsFilters) {
        try {
            this.rootStore.appStore.incrementRequests()

            const correctedFilters = this.isRuckClient(filters.company_id)
                ? { ...filters, entire_network: false, domains: ['ruck.co.uk'] }
                : filters

            const res = await this.insightsService.filterInsights(correctedFilters)

            runInAction(() => {
                this.companyInsights = res
                this.insightsNetwork = correctedFilters.domains
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to filter insights',
                body: this.handleError(error)
            })
        } finally {
            this.insightsFiltersTab = Object.entries(filters.filters).map(([key, value]: [FilterType, string[]]) => ({
                field: key,
                value,
                _id: genKey()
            }))
            this.insightsFilters = InsightsStore.getEmptyFiltersObject()

            this.rootStore.appStore.decrementRequests()
        }
    }

    static getEmptyFiltersObject(): Record<FilterType, FilterValue> {
        return structuredClone({
            [FilterType.Categories]: [],
            [FilterType.InterestIntensity]: [],
            [FilterType.Languages]: [],
            [FilterType.Countries]: [],
            [FilterType.Keywords]: [],
            [FilterType.OS]: [],
            [FilterType.Browser]: [],
            [FilterType.Device]: [],
            [FilterType.Activity]: [],
            [FilterType.Pageviews]: [],
            [FilterType.Sessions]: [],
            [FilterType.RefDomains]: [],
            [FilterType.DesDomains]: [],
            [FilterType.Domains]: [],
            [FilterType.Topics]: [],
            [FilterType.Cities]: [],
            [FilterType.Lookalike]: [],
            [FilterType.AdjacentTopis]: []
        })
    }

    getCustomTaxonomyCategories() {
        return (
            flattenNodesArray(this.companyInsights?.[ChartName.Categories])?.filter(
                (item) => item.taxonomy === TreeMapTaxonomy.Custom && item.id
            ) || []
        )
    }
}
