import { ApiService } from './apiService'
import { Device, SegmentDto, SegmentReach } from '../types'
import { baseApiUrl, site } from '../config'
import { SiteType } from '../types'
import { AdSegment } from '../types/AdPlatforms'
import { BasePubmaticSegment, CategoryItem, FrequencyCap, ImpressionTracker, Segment, Topic } from '../types/Segment'
import { Adtech } from '../types/Pricing'

export class SegmentService extends ApiService {
    protected isPublisherWebSite: boolean

    constructor(baseUrl: string, siteType: SiteType) {
        super(baseUrl)
        this.isPublisherWebSite = siteType === SiteType.Publisher
    }

    async getCategories(): Promise<CategoryItem[]> {
        return this.get('/api/v3/field/interest-category')
    }

    async getOsList(): Promise<Device[]> {
        return Promise.resolve([
            { os: 'android', mobile: true, browser: 'Chrome' },
            { os: 'android', mobile: true, browser: 'Edge' },
            { os: 'android', mobile: true, browser: 'Firefox' },
            { os: 'android', mobile: true, browser: 'Samsung Browser' },
            { os: 'android', mobile: true, browser: 'Other' },
            { os: 'ios', mobile: true, browser: 'Safari' },
            { os: 'ios', mobile: true, browser: 'Chrome' },
            { os: 'ios', mobile: true, browser: 'Edge' },
            { os: 'ios', mobile: true, browser: 'Firefox' },
            { os: 'ios', mobile: true, browser: 'Other' },
            { os: 'windows', mobile: false, browser: 'Chrome' },
            { os: 'windows', mobile: false, browser: 'Edge' },
            { os: 'windows', mobile: false, browser: 'Firefox' },
            { os: 'windows', mobile: false, browser: 'Other' },
            { os: 'macOs', mobile: false, browser: 'Safari' },
            { os: 'macOs', mobile: false, browser: 'Chrome' },
            { os: 'macOs', mobile: false, browser: 'Edge' },
            { os: 'macOs', mobile: false, browser: 'Firefox' },
            { os: 'macOs', mobile: false, browser: 'Other' },
            { os: 'linux', mobile: false, browser: 'Chrome' },
            { os: 'linux', mobile: false, browser: 'Edge' },
            { os: 'linux', mobile: false, browser: 'Firefox' },
            { os: 'linux', mobile: false, browser: 'Other' }
        ])
    }

    async getLocations(): Promise<any> {
        return this.get('/api/v2/field/location')
    }

    async getLanguages(): Promise<string[]> {
        return this.get('/api/v2/field/language')
    }

    async getKeywords(kw: string): Promise<string[]> {
        return this.get(`/api/v2/field/interest-keyword?kw=${kw}`)
    }

    async getTopics(kw: string, controller?: AbortController): Promise<Topic[]> {
        return this.get(`/api/v2/field/interest-topic?topic=${kw}`, undefined, undefined, controller)
    }

    async getTopicsByIds(ids: number[]): Promise<Topic[]> {
        return this.get(`/api/v3/topics?${ids.map((id) => `ids=${id}`).join('&')}`)
    }

    async getReach(): Promise<SegmentReach[]> {
        return this.get(`/api/v2/segment/reach`)
    }

    async getDemoReach(): Promise<SegmentReach[]> {
        return [
            {
                reach: [
                    {
                        date: '2024-05-30',
                        size: 2178
                    },
                    {
                        date: '2024-05-31',
                        size: 2218
                    },
                    {
                        date: '2024-06-04',
                        size: 2220
                    },
                    {
                        date: '2024-06-05',
                        size: 226900
                    },
                    {
                        date: '2024-06-01',
                        size: 2228
                    },
                    {
                        date: '2024-06-03',
                        size: 2225
                    },
                    {
                        date: '2024-06-02',
                        size: 2230
                    }
                ],
                segmentID: 2133
            },
            {
                reach: [
                    {
                        date: '2024-05-30',
                        size: 244
                    },
                    {
                        date: '2024-05-31',
                        size: 250
                    },
                    {
                        date: '2024-06-04',
                        size: 265
                    },
                    {
                        date: '2024-06-05',
                        size: 923930
                    },
                    {
                        date: '2024-06-01',
                        size: 259
                    },
                    {
                        date: '2024-06-03',
                        size: 260
                    },
                    {
                        date: '2024-06-02',
                        size: 260
                    }
                ],
                segmentID: 2140
            },
            {
                reach: [
                    {
                        date: '2024-05-30',
                        size: 94
                    },
                    {
                        date: '2024-05-31',
                        size: 98
                    },
                    {
                        date: '2024-06-04',
                        size: 113
                    },
                    {
                        date: '2024-06-05',
                        size: 1972000
                    },
                    {
                        date: '2024-06-01',
                        size: 102
                    },
                    {
                        date: '2024-06-03',
                        size: 111
                    },
                    {
                        date: '2024-06-02',
                        size: 107
                    }
                ],
                segmentID: 2132
            }
        ]
    }

    async getAll(): Promise<Segment[]> {
        return this.get('/api/v3/segment')
    }

    async create(segment: SegmentDto): Promise<SegmentDto> {
        return this.post('/api/v2/segment', segment)
    }

    async sendToCriteo(segmentId: number): Promise<void> {
        return this.post(`/api/v2/segment/${segmentId}/export/criteo`)
    }

    async getStatusCriteo(segmentId: number): Promise<AdSegment> {
        return this.get(`/api/v2/segment/${segmentId}/export/criteo`)
    }

    async sendToGAM(segmentId: number): Promise<void> {
        return this.post(`/api/v2/segment/${segmentId}/export/gam`)
    }

    async getStatusGAM(segmentId: number): Promise<AdSegment> {
        return this.get(`/api/v2/segment/${segmentId}/export/gam`)
    }

    async sendToXandr(segmentId: number): Promise<void> {
        return this.post(`/api/v2/segment/${segmentId}/export/xandr`)
    }

    async getStatusXandr(segmentId: number): Promise<AdSegment> {
        return this.get(`/api/v2/segment/${segmentId}/export/xandr`)
    }

    async sendToEquativ(segmentId: number): Promise<void> {
        return this.post(`/api/v2/segment/${segmentId}/export/equativ`)
    }

    async getStatusEquativ(segmentId: number): Promise<AdSegment> {
        return this.get(`/api/v2/segment/${segmentId}/export/equativ`)
    }

    async sendToPubmatic(segmentId: number): Promise<void> {
        return this.post(`/api/v2/segment/${segmentId}/export/pubmatic`)
    }

    async getStatusPubmatic(segmentId: number): Promise<BasePubmaticSegment> {
        return this.get(`/api/v2/segment/${segmentId}/export/pubmatic`)
    }

    async getSegmentExportStatus(segmentId: number, adtech: Adtech): Promise<{ status: string }> {
        return this.get(`/api/v3/segment/${segmentId}/export/${adtech}`)
    }

    async exportSegment(segmentId: number, adtech: Adtech): Promise<void> {
        return this.post(`/api/v3/segment/${segmentId}/export/${adtech}`)
    }

    async update(segment: SegmentDto): Promise<SegmentDto> {
        return this.put(`/api/v2/segment/${segment.id}`, segment)
    }

    async publish(id: number): Promise<SegmentDto> {
        return this.post(`/api/v3/segment/${id}/publish`, null)
    }

    async schedule(id: number): Promise<SegmentDto> {
        return this.post(`/api/v2/segment/${id}/schedule`, null)
    }

    async archive(id: number): Promise<SegmentDto> {
        return this.put(`/api/v3/segment/${id}/archive`, null)
    }

    async delete(id: number): Promise<void> {
        return this.del(`/api/v3/segment/${id}`)
    }

    async getMarketAdvertiser(): Promise<SegmentDto[]> {
        const url = '/api/v2/marketplace-segments'
        return this.get(url)
    }

    async filterMarket(query: string): Promise<SegmentDto[]> {
        const url = `/api/v2/marketplace-segments${query}`
        return this.get(url)
    }

    async assign(segment: SegmentDto, campaignId: number): Promise<any> {
        return this.post(`/api/v2/segment/${segment.id}/campaign/${campaignId}`)
    }

    async detach(segment: SegmentDto, campaignId: number): Promise<any> {
        return this.del(`/api/v2/segment/${segment.id}/campaign/${campaignId}`)
    }

    async getPPS(companyId: number): Promise<{ message: string }> {
        return this.get(`/api/v3/company/${companyId}/integration/gam/pps/export`)
    }

    async getPPSAdmin(): Promise<{ message: string }> {
        return this.get(`/api/admin/v3/integration/gam/pps/export`)
    }

    async createImpressionTracker(id: number, freqCap: FrequencyCap[]): Promise<ImpressionTracker> {
        return this.post(`/api/v3/segment/${id}/impressiontracker`, freqCap)
    }

    async getImpressionTracker(id: number): Promise<ImpressionTracker> {
        return this.get(`/api/v3/segment/${id}/impressiontracker`)
    }
}

export const segmentService = new SegmentService(baseApiUrl, site as SiteType)
