import { makeAutoObservable, runInAction, onBecomeObserved, toJS } from 'mobx'
import { SegmentService } from '../services/segmentService'
import { SegmentDto, SegmentReach, SegmentStatus, Device } from '../types'
import { AdStatus } from '../types/AdPlatforms'
import { ToastType } from '../types/ToastData'
import { RootStore } from './rootStore'
import { adminService } from '../services/adminService'
import {
    BasePubmaticSegment,
    FrequencyCap,
    ImpressionTracker,
    Retargeting,
    RetargetingDefinition,
    Segment,
    SegmentType,
    TargetingDto
} from '../types/Segment'
import { LocalStorageKeys } from '../types/LocalStorage'
import { Adtech } from '../types/Pricing'
import { insightsService } from '../services/insightsService'
import { HttpStatusCode } from '../services/types'
import { downloadFile } from '../utils/download'
import { simpleCompareObjects } from '../utils/object'
import { patchSegmentNumberScoreWithString } from '../utils/scoreHelpers'
import { isProduction } from '../config'

interface CurrentSegment {
    id?: number
    companyId?: number
    status: SegmentStatus
    size: number
    targeting?: TargetingDto
    retargeting?: Retargeting
    durationDays: number
    reportUrl?: string
    segmentData?: Record<string, any>
    interestScore?: number
    type: SegmentType
    config?: {
        gam?: {
            ppsEnabled?: boolean
        }
    }
}

export class SegmentStore {
    private segmentService: SegmentService
    private rootStore: RootStore

    newSegmentUI: boolean = localStorage.getItem(LocalStorageKeys.NewSegmentUI)
        ? JSON.parse(localStorage.getItem(LocalStorageKeys.NewSegmentUI) ?? '')
        : true
    languages: string[] = []
    categories: string[] = []
    osList: Device[] | null = null
    segments: Segment[] = []
    segmentsReach: SegmentReach[] = []
    locations: any = null
    error: string | null = null
    equativSegment: boolean = false
    xandrCurateSegment: boolean = false
    xandrMonetizeSegment: boolean = false
    criteoSegment: boolean = false
    gamSegment: boolean = false
    oneTagSegment: boolean = true
    pubmaticSegment: BasePubmaticSegment | null = null
    isBeingPublished: boolean = false
    maxUsersNumber = 0
    allSegments: any[] = []
    currentSegment?: CurrentSegment | null = null
    adminSegment: any = null
    lookalikeCategories: Record<string, number> | null = null
    oldLookalikeCategories: string[] | null = null
    useCustomerServiceEstimate: boolean = false
    isAdv: boolean = false
    impressionTracker: ImpressionTracker | null = null

    constructor(rootStore: RootStore, segmentService: SegmentService) {
        this.segmentService = segmentService
        this.rootStore = rootStore

        makeAutoObservable(this)
        this.resetCurrentSegment()

        onBecomeObserved(this, 'languages', async () => {
            if (this.languages.length > 0) return
            const res = await this.segmentService.getLanguages()
            runInAction(() => {
                const brokenLanguages = [
                    'ace',
                    'ach',
                    'ckb',
                    'enus',
                    'hr',
                    'kpe',
                    'la',
                    'lij',
                    'mo',
                    'other',
                    'sat',
                    'sc',
                    'scn',
                    'sm',
                    'tl',
                    'und',
                    'us',
                    'yue',
                    'zz',
                    'an',
                    'apw',
                    'eng',
                    'gb',
                    'sh'
                ]
                this.languages = res ? res.filter((x) => !brokenLanguages.includes(x)) : []
            })
        })
        onBecomeObserved(this, 'categories', async () => {
            if (this.categories.length > 0) return
            const res = await this.segmentService.getCategories()
            runInAction(() => {
                this.categories = res ? res : []
            })
        })
        onBecomeObserved(this, 'osList', async () => {
            if (this.osList) return
            const res = await this.segmentService.getOsList()
            runInAction(() => {
                this.osList = res
            })
        })
        onBecomeObserved(this, 'segments', async () => {
            const res = await this.segmentService.getAll()

            runInAction(() => {
                this.rootStore.appStore.pendingRequests++
                this.segments = res ? res : []
                this.rootStore.appStore.pendingRequests--
            })
        })
        onBecomeObserved(this, 'segmentsReach', async () => {
            if (this.segmentsReach.length > 0) return
            const res = this.rootStore.companyStore.isDemo ? await this.segmentService.getDemoReach() : await this.segmentService.getReach()

            runInAction(() => {
                this.rootStore.appStore.pendingRequests++
                this.segmentsReach = res
                this.rootStore.appStore.pendingRequests--
            })
        })
        onBecomeObserved(this, 'locations', async () => {
            if (this.locations) return
            const res = await this.segmentService.getLocations()
            runInAction(() => {
                this.locations = res
            })
        })
        onBecomeObserved(this, 'maxUsersNumber', async () => {
            if (this.maxUsersNumber) return

            const dto = this.mapCurrentToSegmentDto()
            dto.name = 'test'
            dto.companyId = this.currentSegment?.companyId || this.rootStore.companyStore.myCompany.company.id
            dto.targeting = {
                language: null,
                category: null,
                deviceOs: null,
                deviceBrowser: null,
                deviceType: null,
                country: null,
                city: null,
                keyword: null,
                topicIds: null,
                gender: null,
                networth: null,
                lookalikeCats: null,
                sizeRecency: ['180']
            }

            patchSegmentNumberScoreWithString(dto)

            const resp =
                process.env.BRANCH === 'master'
                    ? await this.segmentService.estimateClickhouseProd(dto, this.isAdv)
                    : await this.segmentService.estimateClickhouseDev(dto, this.isAdv)
            runInAction(() => {
                this.maxUsersNumber = resp.size
            })
        })
    }

    convertLocalDateToUTC(localDate: Date, endDate: boolean) {
        const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000)

        if (endDate) {
            utcDate.setUTCHours(23)
            utcDate.setUTCMinutes(59)
            utcDate.setUTCSeconds(59)
            utcDate.setUTCMilliseconds(999)
        }

        return utcDate.toISOString()
    }

    convertUTCDateToLocal(utcDate: string) {
        const localDate = new Date(utcDate)
        if (localDate.getUTCHours() > 0) {
            localDate.setUTCHours(0)
            localDate.setUTCMinutes(0)
            localDate.setUTCSeconds(0)
            localDate.setUTCMilliseconds(0)

            const offset = new Date().getTimezoneOffset() * 60 * 1000
            const localDate1 = new Date(localDate.getTime() - offset)
            localDate1.setHours(0, 0, 0, 0)

            return localDate1
        }
        if (localDate.getUTCHours() === 0) {
            const offset = new Date().getTimezoneOffset() * 60 * 1000
            const localDate2 = new Date(localDate.getTime() - offset)

            localDate2.setHours(0, 0, 0, 0)

            return localDate2
        }
    }

    resetCurrentSegment() {
        runInAction(() => {
            this.currentSegment = {
                status: SegmentStatus.Draft,
                type: SegmentType.Targeting,
                size: 0,
                interestScore: 0,
                durationDays: 120,
                retargeting: null,
                //  test  data to develop retargeting view
                // {
                //     definition: {
                //         properties: [
                //             {
                //                 id: 1,
                //                 value: null
                //             }
                //         ]
                //     },
                //     domains: []
                // },
                companyId: this.rootStore.companyStore?.myCompany?.company?.id,
                targeting: {
                    category: null,
                    city: null,
                    country: null,
                    deviceBrowser: null,
                    deviceOs: null,
                    deviceType: null,
                    keyword: null,
                    language: null,
                    sizeRecency: null,
                    topicIds: null,
                    gender: null,
                    networth: null,
                    lookalikeCats: null
                },
                segmentData: {
                    name: '',
                    domains: []
                }
            }

            this.setImpressionTracker(null)
        })
    }

    get segment() {
        return this.mapCurrentToSegmentDto()
    }

    private mapDtoToCurrent(dto: SegmentDto): CurrentSegment {
        const { id, companyId, status, size, durationDays, interestScore, targeting, retargeting, type, config } = dto

        const res: CurrentSegment = {
            id: id,
            companyId: companyId,
            status: status,
            size: size,
            type: type,
            durationDays: durationDays,
            interestScore: interestScore || 0,
            targeting: targeting,
            retargeting: retargeting,
            config: config || null,
            segmentData: {
                name: dto.name
            }
        }

        return res
    }

    private mapCurrentToSegmentDto() {
        const { segmentData, id, companyId, status, size, durationDays, targeting, retargeting, type, interestScore, config } =
            this.currentSegment

        const dto: SegmentDto = {
            id,
            companyId: companyId >= 0 ? companyId : this.rootStore.companyStore?.myCompany?.company?.id,
            size,
            type,
            durationDays,
            name: segmentData.name,
            status: status,
            interestScore,
            targeting,
            retargeting,
            config: config || null
        }
        if (retargeting) {
            delete dto.definition
        } else {
            delete dto.retargeting
        }
        return dto
    }
    setSegmentName = (name: string) => {
        this.currentSegment.segmentData.name = name
    }
    setSegmentFilters(data: TargetingDto) {
        this.currentSegment.targeting = data
    }
    setSegmentData(data: Record<string, string | number[]>) {
        this.currentSegment.segmentData = data
    }
    setDurationData(data: number) {
        this.currentSegment.durationDays = data
    }
    setRetargeting(data: Retargeting) {
        this.currentSegment.retargeting = data
    }
    setLookalikeCats(data: string[] | null) {
        this.currentSegment.targeting.lookalikeCats = data
    }
    setInterestScore(data: number) {
        this.currentSegment.interestScore = data
    }
    setSegmentSize(data: number) {
        this.currentSegment.size = data
    }
    setRetargetingDifinition(data: RetargetingDefinition) {
        this.currentSegment.retargeting.definition = data
    }
    setType(data: SegmentType) {
        this.currentSegment.type = data
    }
    setConfig(data: { gam?: { ppsEnabled?: boolean }; public?: boolean }) {
        this.currentSegment.config = data
    }
    setDomains(data: string[]) {
        this.currentSegment.retargeting.domains = data
    }
    setCompanyId(data: number) {
        this.currentSegment.companyId = data
    }
    setEstimationService(data: boolean) {
        this.useCustomerServiceEstimate = data
    }
    setIsAdv(data: boolean) {
        this.isAdv = data
    }

    async getSegments() {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const segments = await this.segmentService.getAll()
            runInAction(() => {
                this.segments = segments
            })
        } catch (error) {
            runInAction(() => {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Failed to get segments'
                })
                runInAction(() => {
                    this.error = error
                })
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async setCurrentSegment(id: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const segments = await this.segmentService.getAll()
            let current = segments.find((x) => x.segment.id === id)
            if (typeof current === 'undefined' && this.rootStore.authStore.isAdmin) {
                const resp = await adminService.getSegmentAdmin(id)
                runInAction(() => {
                    this.adminSegment = resp
                    this.segments = segments ? segments : []
                    current = this.adminSegment
                })
            }
            if (current) {
                runInAction(() => {
                    this.segments = segments ? segments : []
                    this.oldLookalikeCategories = current?.segment?.targeting?.lookalikeCats?.length
                        ? current?.segment?.targeting?.lookalikeCats
                        : null
                    this.lookalikeCategories = null
                    this.currentSegment = this.mapDtoToCurrent(current.segment)
                })
            } //TODO: else - throw 404!
        } catch (error) {
            // eslint-disable-next-line
            console.error(error)
            runInAction(() => {
                this.error = error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async save() {
        if (this.currentSegment.id) {
            this.update(false)
        }
    }

    async createEmpty(name: string, clonedSegment?: SegmentDto) {
        runInAction(() => {
            this.error = null
        })
        const dto = this.mapCurrentToSegmentDto()
        dto.name = name
        dto.segmentData = {
            name: dto.name
        }
        if (this.rootStore.appStore.isCreateRetargetingSegment) {
            dto.retargeting = {
                definition: {
                    properties: []
                }
            }
            dto.type = SegmentType.Retargeting
            delete dto.targeting
        } else {
            dto.targeting = {
                language: null,
                category: null,
                deviceOs: null,
                deviceBrowser: null,
                deviceType: null,
                country: null,
                city: null,
                keyword: null,
                topicIds: null,
                gender: null,
                networth: null,
                lookalikeCats: null,
                sizeRecency: ['180']
            }
        }

        if (clonedSegment) {
            const _c = toJS(clonedSegment)
            dto.targeting = _c.targeting
            dto.retargeting = _c.retargeting
            dto.type = _c.type
            dto.interestScore = _c.interestScore
            dto.lookalikeCats = _c.lookalikeCats
            dto.durationDays = _c.durationDays
            dto.config = { ..._c?.config }
        }

        this.currentSegment = dto
    }

    async create() {
        runInAction(() => {
            this.error = null
        })
        const dto = this.mapCurrentToSegmentDto()

        try {
            this.rootStore.appStore.incrementRequests()

            const resp = await this.segmentService.create(dto)

            runInAction(() => {
                this.currentSegment.id = resp.id
                this.currentSegment.size = resp.size
                this.currentSegment.type = resp.type
                this.currentSegment.durationDays = resp.durationDays
                this.segments.push({ prices: [], segment: resp })
                this.rootStore.appStore.setCreateRetargetingSegment(false)
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to create the segment'
            })
            runInAction(() => {
                this.error = error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    private async update(hideToast: boolean, isSilent = false) {
        runInAction(() => {
            this.error = null
        })

        const dto = this.mapCurrentToSegmentDto()

        try {
            this.rootStore.appStore.incrementRequests()
            if (dto.targeting?.lookalikeCats) {
                dto.targeting.lookalikeCats = dto.targeting.lookalikeCats?.length > 0 ? dto.targeting.lookalikeCats : null
            }
            const resp = this.rootStore.authStore.isAdmin
                ? await adminService.updateSegmentAdmin(this.mapCurrentToSegmentDto())
                : await this.segmentService.update(dto)
            const index = this.segments.findIndex((x) => x.segment?.id === dto.id)
            runInAction(() => {
                this.currentSegment.size = resp.size
                this.currentSegment.durationDays = resp.durationDays
                this.segments[index] = { prices: this.segments[index].prices, segment: resp }
            })
        } catch (error) {
            runInAction(() => {
                this.error = error
            })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Saved',
                body: error?.message ? error?.message : 'The segment was not saved',
                isHidden: hideToast
            })
            this.rootStore.appStore.decrementRequests()
        } finally {
            if (!this.error) {
                !isSilent &&
                    this.rootStore.appStore.setToastNotification({
                        type: ToastType.Success,
                        title: 'Saved',
                        body: 'The segment was successfully saved',
                        isHidden: hideToast
                    })
            }

            this.rootStore.appStore.decrementRequests()
        }
    }

    async getLookalikeCategories() {
        runInAction(() => {
            this.error = null
        })
        const dto = this.mapCurrentToSegmentDto()

        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await insightsService.getDirectLookalikeCategories(dto.targeting.category, this.currentSegment.companyId)
            const lookalikeCats = Object.keys(resp)
            runInAction(() => {
                if (!simpleCompareObjects(this.lookalikeCategories, resp)) {
                    this.currentSegment.targeting.lookalikeCats?.every(
                        (x) => Array.isArray(this.oldLookalikeCategories) && this.oldLookalikeCategories.includes(x)
                    ) && this.currentSegment.targeting.lookalikeCats?.length > 0
                        ? (this.currentSegment.targeting.lookalikeCats = this.oldLookalikeCategories)
                        : (this.currentSegment.targeting.lookalikeCats = lookalikeCats.length ? [...lookalikeCats] : null)

                    this.lookalikeCategories = resp
                }
            })
        } catch (error) {
            runInAction(() => {
                this.error = error
            })
        } finally {
            if (!Object.keys(this.lookalikeCategories || {}).length) {
                this.setSegmentFilters({ ...this.currentSegment.targeting, lookalikeCats: this.currentSegment.targeting.lookalikeCats })
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Warning,
                    title: 'No adjacent audiences found',
                    body: 'There is no category meeting affinity criteria'
                })
            }
            this.rootStore.appStore.decrementRequests()
        }
    }

    async estimate(shouldCheckLookalikeCats = false) {
        runInAction(() => {
            this.error = null
        })

        let resp: { size: number; singleSite: number }

        const handleEstimate = async (dto: SegmentDto) => {
            if (!this.useCustomerServiceEstimate) {
                patchSegmentNumberScoreWithString(dto)

                const data =
                    process.env.BRANCH === 'master'
                        ? await this.segmentService.estimateClickhouseProd(dto, this.isAdv)
                        : await this.segmentService.estimateClickhouseDev(dto, this.isAdv)
                return data
            } else {
                const data = this.rootStore.authStore.isAdmin ? await adminService.estimateAdmin(dto) : await this.segmentService.estimate(dto)
                return data
            }
        }

        try {
            this.rootStore.appStore.incrementRequests()
            this.rootStore.appStore.setIsCurrentlyEstimated(true)
            if (
                (this.currentSegment.targeting?.lookalikeCats?.length && this.currentSegment.targeting?.category?.length) ||
                shouldCheckLookalikeCats
            ) {
                const dto = this.mapCurrentToSegmentDto()
                await this.getLookalikeCategories()
                resp = await handleEstimate(dto)
            } else {
                const dto = this.mapCurrentToSegmentDto()
                resp = await handleEstimate(dto)
            }
            runInAction(() => {
                this.currentSegment.size = resp.size
            })
        } catch (error) {
            runInAction(() => {
                this.error = error
            })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Estimated',
                body: error?.message ? error.message : 'The segment was not estimated'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            this.rootStore.appStore.setIsCurrentlyEstimated(false)
        }
    }

    async delete(id: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()

            const index = this.segments.findIndex((x) => x?.segment.id === id)

            const companyId = index >= 0 ? this.rootStore.companyStore.myCompany.company.id : this.adminSegment.companyId

            this.rootStore.authStore.isAdmin ? await adminService.deleteSegmentAdmin(id, companyId) : await this.segmentService.delete(id)

            if (index >= 0) {
                runInAction(() => {
                    this.segments.splice(index, 1)
                })
            }
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to delete the segment'
            })
            runInAction(() => {
                this.error = error?.message || error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async archive(id: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await this.segmentService.archive(id)
            const index = this.segments.findIndex((x) => x?.segment.id === id)

            if (index >= 0) {
                runInAction(() => {
                    this.segments[index] = { prices: this.segments[index].prices, segment: resp }
                    this.currentSegment.status = resp.status
                })
            }
        } catch (error) {
            runInAction(() => {
                this.error = error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getSegmentExportStatus(companyId: number, segment: SegmentDto, adtech: Adtech) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = this.rootStore.authStore.isAdmin
                ? await adminService.getAdminSegmentExportStatus(companyId, segment.id, adtech)
                : await this.segmentService.getSegmentExportStatus(segment.id, adtech)
            runInAction(() => {
                switch (adtech) {
                    case Adtech.XandrCurate:
                        this.xandrCurateSegment = resp.status === AdStatus.Active
                        break
                    case Adtech.XandrMonetize:
                        this.xandrMonetizeSegment = resp.status === AdStatus.Active
                        break
                    case Adtech.Equativ:
                        this.equativSegment = resp.status === AdStatus.Active
                        break
                    case Adtech.Criteo:
                        this.criteoSegment = resp.status === AdStatus.Active
                        break
                    case Adtech.GAM:
                        this.gamSegment = resp.status === AdStatus.Active
                        break
                    case Adtech.Pubmatic:
                        this.pubmaticSegment = resp as any
                        break
                    case Adtech.Onetag:
                        this.oneTagSegment = resp.status === AdStatus.Active
                        break
                }
            })
        } catch (error) {
            runInAction(() => {
                this.error = error.message
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async exportSegment(segmentId: number, adtech: Adtech) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            await this.segmentService.exportSegment(segmentId, adtech)
        } catch (error) {
            runInAction(() => {
                this.error = error
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Not sent!',
                    body: 'Segment was not sent',
                    isHidden: false
                })
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Sent!',
                    body: 'Segment was sent succesfully',
                    isHidden: false
                })
            }
        }
    }

    async publish(id: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            this.isBeingPublished = true
            const resp = await this.segmentService.publish(id)
            const index = this.segments.findIndex((x) => x?.segment.id === id)
            runInAction(() => {
                this.segments[index] = { prices: this.segments[index].prices, segment: resp }
                this.currentSegment.status = resp.status
            })
            return resp
        } catch (error) {
            runInAction(() => {
                this.error = error
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Not Activated',
                    body: `You can not create segments with estimated size less than ${isProduction ? 1000 : 10}`,
                    isHidden: false
                })
                this.isBeingPublished = false
                this.rootStore.appStore.decrementRequests()
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Activation,
                    title: 'Activated!',
                    body: 'Segment was activated succesfully',
                    isHidden: false
                })
                this.isBeingPublished = false
            }
        }
    }

    async schedule(id: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            this.isBeingPublished = true
            await this.update(true)
            if (this.error) {
                throw this.error
            }
            const resp = await this.segmentService.schedule(id)
            const index = this.segments.findIndex((x) => x?.segment.id === id)
            runInAction(() => {
                this.segments[index] = { prices: this.segments[index].prices, segment: resp }
                this.currentSegment.status = resp.status
            })
            return resp
        } catch (error) {
            runInAction(() => {
                this.error = error
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Not Scheduled!',
                    body: 'Segment was not scheduled',
                    isHidden: false
                })
                this.isBeingPublished = false
                this.rootStore.appStore.decrementRequests()
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Activation,
                    title: 'Scheduled!',
                    body: 'Segment was scheduled succesfully',
                    isHidden: false
                })
                this.isBeingPublished = false
            }
        }
    }

    async downloadPPS(id: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.segmentService.getPPS(id)

            downloadFile(res.message, `gampps_export_${new Date().toLocaleDateString()}.csv`)
        } catch (error) {
            runInAction(() => {
                this.error = error
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Not Donwloaded!',
                    body: 'PPS file was not downloaded',
                    isHidden: false
                })
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async downloadPPSAdmin() {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.segmentService.getPPSAdmin()

            downloadFile(res.message, `all_gampps_export_${new Date().toLocaleDateString()}.csv`)
        } catch (error) {
            runInAction(() => {
                this.error = error
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Not Donwloaded!',
                    body: 'PPS file was not downloaded',
                    isHidden: false
                })
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async createImpressionTracker(id: number, freqCap: FrequencyCap[]) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await this.segmentService.createImpressionTracker(id, freqCap)

            runInAction(() => {
                this.setImpressionTracker(resp)
            })
            return resp
        } catch (error) {
            runInAction(() => {
                this.error = error
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Impression tracker was not created',
                    body: error?.message,
                    isHidden: false
                })
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Activation,
                    title: 'Created!',
                    body: 'Impression tracker was created succesfully',
                    isHidden: false
                })
            }
        }
    }

    async getImpressionTracker(id: number) {
        runInAction(() => {
            this.error = null

            // clear prev value
            this.setImpressionTracker(null)
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await this.segmentService.getImpressionTracker(id)

            runInAction(() => {
                this.setImpressionTracker(resp)
            })
            return resp
        } catch (error) {
            //TODO: add error types
            //'Not found' impression tracker is valid  case, do not show the error
            if (error.status !== HttpStatusCode.NotFound) {
                runInAction(() => {
                    this.error = error
                })
            }
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    setImpressionTracker(value: ImpressionTracker | null) {
        this.impressionTracker = value
    }
}
