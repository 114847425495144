import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { rootStore } from '../store/rootStore'
import { reportError } from './reportError'

const apiKey = '5dbe0748b2abf41b336e7bf8e93072dd'

console.log('Release:', process.env.NODE_ENV)

if (location.hostname !== 'localhost') {
    Bugsnag.start({
        apiKey,
        releaseStage: process.env.NODE_ENV,
        plugins: [new BugsnagPluginReact()],
        onError: function (event) {
            try {
                if (rootStore.authStore.user) {
                    event.setUser(`${rootStore.authStore.user.id}`, rootStore.authStore.user.email)
                } else {
                    event.setUser('_anon_')
                }
            } catch (e) {
                reportError(e)
            }
        }
    })

    BugsnagPerformance.start({ apiKey })
}
