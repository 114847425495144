import { SegmentDto } from '../types'

export enum InterestScore {
    Moderate = 'Moderate',
    Good = 'Good',
    High = 'High',
    VeryHigh = 'Very High',
    Highest = 'Highest'
}

const scoreNumbers = [0, 0.15, 0.35, 0.9, 2]

export const mapNumberToInterestScore = (value = 0): InterestScore => {
    switch (value) {
        case scoreNumbers[0]:
            return InterestScore.Moderate
        case scoreNumbers[1]:
            return InterestScore.Good
        case scoreNumbers[2]:
            return InterestScore.High
        case scoreNumbers[3]:
            return InterestScore.VeryHigh
        case scoreNumbers[4]:
            return InterestScore.Highest
    }
}

export const mapInterestScoreToNumber = (value: string = InterestScore.Moderate): number => {
    switch (value) {
        case InterestScore.Moderate:
            return scoreNumbers[0]
        case InterestScore.Good:
            return scoreNumbers[1]
        case InterestScore.High:
            return scoreNumbers[2]
        case InterestScore.VeryHigh:
            return scoreNumbers[3]
        case InterestScore.Highest:
            return scoreNumbers[4]
    }
}

export const patchSegmentNumberScoreWithString = (dto: SegmentDto) => {
    if (dto.interestScore !== undefined) {
        // temporary fix until the BE will be updated for accept score string value
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dto.interestScore = mapNumberToInterestScore(dto.interestScore)
    }
}
